// extracted by mini-css-extract-plugin
export var appendix = "index-module--appendix--RzEw0";
export var commonPricingComponentContainer = "index-module--commonPricingComponentContainer--p61pl";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--tiVWq";
export var link = "index-module--link--kEoQ1";
export var list_lower_alpha = "index-module--list_lower_alpha--ac-02";
export var list_lower_roman = "index-module--list_lower_roman--iBpBb";
export var list_lower_solid = "index-module--list_lower_solid--+LhrZ";
export var list_number = "index-module--list_number--Q77+a";
export var margins = "index-module--margins--oMp0V";
export var pageTitle = "index-module--pageTitle--fCMHs";
export var section_title = "index-module--section_title--JirfL";
export var text = "index-module--text--g5XDX";
export var text_bold = "index-module--text_bold--QvZEN";
export var text_bold2 = "index-module--text_bold2--VHLO0";
export var text_bold_helper = "index-module--text_bold_helper--H-3Vk";
export var text_helper = "index-module--text_helper--NU+Qs";
export var ul_list = "index-module--ul_list--MYtKI";
export var ul_title = "index-module--ul_title--rifra";
export var ul_title2 = "index-module--ul_title2--k5AXn";
export var wrapper = "index-module--wrapper--IKjsB";